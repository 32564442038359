<template>
  <c-box
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#FFF', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['auto', '74vh']"
  >
    <Stepper
      margin-top="16px"
      :margin-bottom="['12px', '20px']"
      :steps="[
        {
          label: 'Informasi Pribadi',
        },
        {
          label: 'Periksa',
          isCurrentStep: true,
        },
        {
          label: 'Pembayaran',
        },
      ]"
    />

    <BaseText
      size-mobile="18px"
      size-desktop="28px"
      text-align="center"
    >
      Periksa Pesanan
    </BaseText>

    <c-box
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['0 12px', '30px']"
      :margin-top="['22px', '20px']"
    >
      <c-flex
        justify="space-between"
        align="center"
      >
        <BaseText
          size-mobile="16px"
          size-desktop="20px"
        >
          Detail Program
        </BaseText>

        <BaseText
          size-mobile="12px"
          size-desktop="16px"
          as="a"
          color="#D32737"
          @click="handleChangeProgram"
        >
          Ganti Program
        </BaseText>
      </c-flex>

      <BaseDivider />

      <c-box
        :padding="['0 7px', '0']"
      >
        <c-flex
          :gap="['10px', '30px']"
          align-items="center"
          :margin="['20px 0', '40px 0']"
        >
          <c-image
            v-if="productImage"
            border-radius="12px"
            :width="['70px', '120px']"
            :height="['89px', '153px']"
            object-fit="cover"
            :src="productImage"
            alt="Product Image"
          />
          <c-box>
            <BaseText
              size-mobile="16px"
              size-desktop="28px"
            >
              {{ productName }} {{ productServiceName }} ({{ duration }})
            </BaseText>

            <BaseText
              size-mobile="12px"
              size-desktop="16px"
              as="a"
              @click="handleShowDetailProgram({ isKonsultasi: product?.isKonsultasi === 1 })"
            >
              Selengkapnya
            </BaseText>

            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              {{ formatCurrency(subTotalPrice) }}
            </BaseText>
          </c-box>
        </c-flex>

        <BaseDivider />

        <c-flex
          justify="space-between"
          align="start"
          gap="10px"
          :margin="['16px 0', '20px 0']"
        >
          <FormInput
            v-model="couponCode"
            is-required
            :is-disabled="couponDetail.isValid"
            :is-invalid="couponDetail.isValid === false"
            label="Kode Kupon"
            placeholder="Masukkan kode kupon"
            :icon-left-element="require('@/assets/icons/icon-coupon.svg')"
            :icon-left-element-color="couponDetail.isValid === false ? '#D32737' : '#008C81'"
            full-width
            is-helper
            helper-text-type="questionmark"
            helper-text="Pelajari lebih lanjut"
            helper-text-color="#008C81"
            is-without-margin-bottom
            @blur="$v.couponCode.$touch"
            @helper-text-click="handleShowFaqCoupon"
            @trigger-change="couponDetail.isValid = null"
          />
          <BaseButton
            :color="couponDetail.isValid ? 'danger' : 'primary'"
            size="small"
            variant="contained"
            border-radius="1000px"
            :margin-top="['33px', '35px']"
            padding="0 20px"
            :disabled="$v.couponCode.$invalid"
            :is-loading="isLoadingApplyCoupon"
            @click="handleApplyCoupon"
          >
            {{ couponDetail.isValid ? 'Hapus' : 'Terapkan' }}
          </BaseButton>
        </c-flex>

        <BaseDivider :display="['none', 'block']" />

        <BaseText
          size-mobile="16px"
          size-desktop="20px"
          :margin-top="['30px', '20px']"
        >
          Detail Pembayaran
        </BaseText>

        <BaseDivider />

        <c-flex
          justify="space-between"
          align="center"
          gap="10px"
          :margin-top="['16px', '20px']"
        >
          <BaseText
            size-mobile="12px"
            size-desktop="16px"
          >
            Subtotal
          </BaseText>
          <BaseText
            size-mobile="14px"
            size-desktop="20px"
          >
            {{ formatCurrency(subTotalPrice) }}
          </BaseText>
        </c-flex>

        <c-flex
          justify="space-between"
          align="center"
          gap="10px"
          :margin-top="['16px', '20px']"
        >
          <BaseText
            size-mobile="12px"
            size-desktop="16px"
          >
            Potongan
          </BaseText>
          <BaseText
            size-mobile="14px"
            size-desktop="20px"
          >
            {{ formatCurrency(discount) }}
          </BaseText>
        </c-flex>
      </c-box>

      <BaseDivider
        :display="['block', 'none']"
        :margin-top="['16px', '20px']"
      />

      <c-flex
        justify="space-between"
        align="center"
        gap="10px"
        :margin-top="['16px', '20px']"
      >
        <BaseText
          size-mobile="14px-2"
          size-desktop="18px"
        >
          Total
        </BaseText>
        <BaseText
          size-mobile="18px"
          size-desktop="28px"
          color="primary.400"
        >
          {{ formatCurrency(totalPrice) }}
        </BaseText>
      </c-flex>
    </c-box>

    <c-flex
      :padding="['0 12px', '0']"
      :gap="['4px', '16px']"
      :margin-top="['16px', '20px']"
    >
      <BaseButton
        size="large"
        variant="outlined"
        w="100%"
        border-radius="1000px"
        @click="handlePrevious"
      >
        Kembali
      </BaseButton>
      <BaseButton
        size="large"
        variant="contained"
        w="100%"
        border-radius="1000px"
        :is-loading="isLoadingCheckout"
        @click="handleNext"
      >
        Selanjutnya
      </BaseButton>
    </c-flex>

    <ModalCouponLearnMore
      :is-open="isOpenModalCouponLearnMore"
      @set-is-open="isOpenModalCouponLearnMore = $event"
    />

    <ModalChangeProgram
      :is-open="isOpenModalChangeProgram"
      @set-is-open="isOpenModalChangeProgram = $event"
      @on-confirm="handleOnConfirmChangeProgram"
    />

    <ModalCouponInvalid
      :is-open="isOpenModalCouponInvalid"
      @handleClose="isOpenModalCouponInvalid = false"
    />

    <ModalInfo
      for="errorCannotPurchaseDifferentProgram"
      :with-button-close="false"
      :is-open="isOpenModalErrorProgramCannotPurchase"
      @close="close"
    />
    
    <ModalErrorProgramCannotPurchaseKonsultation
      :is-open="isModalOpen.ModalErrorProgramCannotPurchaseKonsultation"
      @handleClose="isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = false"
    />
  </c-box>
</template>

<script>
import Stepper from '@/components/elements/stepper.vue'
import BaseText from '@/components/elements/base-text.vue'
import FormInput from '@/views/client/kuisioner/forms/form-input.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { required } from 'vuelidate/lib/validators'
import BaseDivider from '@/components/elements/base-divider.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ModalCouponLearnMore from '@/components/modal-coupon-learn-more.vue'
import ModalChangeProgram from '@/components/modal-change-program.vue'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import { formatCurrency } from '@/utils/format-currency'
import ModalCouponInvalid from '@/views/client/cart/check-order/_widgets/modal-coupon-invalid.vue'
import ModalInfo from '@/components/widgets/modal-info/index.vue'
import ModalErrorProgramCannotPurchaseKonsultation
  from '@/views/client/cart/check-order/_widgets/modal-error-program-cannot-purchase-konsultation.vue'

export default {
  name: 'Buy3Page',
  components: {
    ModalInfo,
    ModalCouponInvalid,
    ModalCouponLearnMore,
    ModalChangeProgram,
    BaseDivider,
    BaseButton,
    FormInput,
    BaseText,
    Stepper,
    ModalErrorProgramCannotPurchaseKonsultation,
  },
  data() {
    return {
      isOpenModalCouponInvalid: false,
      isOpenModalCouponLearnMore: false,
      isOpenModalChangeProgram: false,
      isOpenModalErrorProgramCannotPurchase: false,
      isLoadingApplyCoupon: false,
      isLoadingCheckout: false,
      couponCode: '',
      productServiceId: this.$route.query.product_service_id,
      productImage: '',
      productName: '...',
      productServiceName: '...',
      duration: '...',
      subTotalPrice: 0,
      couponDetail: {
        id: '',
        type: '',
        totalDiscount: 0,
        isValid: null,
      },
      isModalOpen: {
        ModalErrorProgramCannotPurchaseKonsultation: false,
      },
      product: null,
    }
  },
  validations: {
    couponCode: { required },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      selectedCart: 'cart/selectedCart',
      userType: 'userType/userType',
    }),
    discount() {
      if (this.couponDetail.type === 'percent') {
        return this.subTotalPrice * (this.couponDetail.totalDiscount / 100)
      }
      return this.couponDetail.totalDiscount
    },
    totalPrice() {
      return this.subTotalPrice - this.discount
    },
  },
  watch: {
    userType: {
      handler(newValue, oldValue) {
        if (newValue && (newValue != '-') && (newValue != oldValue)) {
          // POSTHOG capture `plan purchased`
          this.$posthog.capture('plan purchased', {
            userType: this.userType ?? 'not-registered',
            program: {
              id: this.product?.productId,
              name: this.product?.product?.name,
              productService: {
                id: this.product?.id,
                name: this.product?.name,
                programsService: this.product?.programsService,
                duration: this.product?.duration,
                price: this.product?.price,
              },
            },
          })
        }
      },
    }, 
  },
  async mounted() {
    if (!this.isAuthenticated) {
      this.handlePrevious()
      return
    }
    await this.init()
    this.triggerUpdateUserType()
  },
  methods: {
    ...mapActions({
      getProductServiceId: 'general/getProductServiceId',
      reqCheckout: 'clients/reqCheckout',
      reqCheckCoupon: 'clients/reqCheckCoupon',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
    }),
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    formatCurrency,
    handlePrevious() {
      if (this.isAuthenticated) {
        this.$router.back()
        return
      }
      this.$router.push({
        name: 'client.buy-program.step-1',
        query: this.$route.query,
      })
    },
    async handleNext() {
      try {
        this.isLoadingCheckout = true
        const res = await this.reqCheckout({
          productServiceId: this.productServiceId,
          couponId: this.couponDetail?.id,
          qty: 1,
        })

        // POSTHOG capture `order created`
        const selectedProgram = {
          productService: this.product,
          coupon: this.couponDetail.isValid ? this.couponDetail : null,
          qty: 1,
        }
        this.$posthog.capture('order created', {
          userType: this.userType ?? 'not-registered',
          program: selectedProgram,
        })
        window.fbq('track', 'CheckOut')
        
        this.$router.push({
          name: 'client.buy-program.payment',
          query: { transaction_id: res.data?.id },
        })
      } catch (e) {
        if (this.product?.isKonsultasi === 1) {
          this.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = true
          return
        } else if (parseErrorCatch(e).startsWith('cannot checkout, active program found :')) {
          this.isOpenModalErrorProgramCannotPurchase = true
          return
        }
      } finally {
        this.isLoadingCheckout = false
      }
    },
    handleChangeProgram() {
      this.isOpenModalChangeProgram = true
    },
    handleOnConfirmChangeProgram() {
      this.isOpenModalChangeProgram = false
      this.$router.push({
        name: 'client.buy-program.step-1',
        query: this.$route.query,
      })
    },
    handleShowDetailProgram({ isKonsultasi }) {
      if (isKonsultasi) {
        this.$router.push({
          name: 'client.program.consultation',
        })
        return
      }
      this.$router.push({
        name: 'client.buy-program.step-1',
        query: this.$route.query,
      })
    },
    resetCoupon() {
      this.couponCode = ''
      this.couponDetail = {
        id: '',
        type: '',
        totalDiscount: 0,
        isValid: null,
      }
    },
    async handleApplyCoupon() {
      if (this.couponDetail.isValid) {
        this.resetCoupon()
        return
      }
      try {
        this.isLoadingApplyCoupon = true
        const res = await this.reqCheckCoupon({
          couponCode: this.couponCode,
          productServiceId: this.productServiceId,
        })
        this.couponDetail = res.data
        this.couponDetail.isValid = true
      } catch (e) {
        this.isOpenModalCouponInvalid = true
        this.couponDetail.isValid = false
      } finally {
        this.isLoadingApplyCoupon = false
      }
    },
    handleShowFaqCoupon() {
      this.isOpenModalCouponLearnMore = true
    },
    close() {
      this.isOpenModalErrorProgramCannotPurchase = false
    },
    async init() {
      try {
        if (!this.productServiceId) {
          this.$toast({
            status: 'error',
            title: 'Error',
            description: parseErrorCatch({ message: 'ERR_NO_PRODUCT_SERVICE_ID' }),
            duration: 3000,
          })
          this.$router.push({ name: 'client.buy-program.step-1' })
        }
        const res = await this.getProductServiceId(this.productServiceId)
        this.productImage = res.data?.product?.photoUrl || ''
        this.productName = res.data?.product?.name || ''
        this.productServiceName = res.data?.programsService || ''
        this.duration = res.data?.duration ? `${res.data?.duration} hari` : ''
        this.subTotalPrice = res.data?.price || ''
        this.product = res.data
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
        this.$router.push('/')
      }
    },
  },
}
</script>
