var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#FFF', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['auto', '74vh']
    }
  }, [_c('Stepper', {
    attrs: {
      "margin-top": "16px",
      "margin-bottom": ['12px', '20px'],
      "steps": [{
        label: 'Informasi Pribadi'
      }, {
        label: 'Periksa',
        isCurrentStep: true
      }, {
        label: 'Pembayaran'
      }]
    }
  }), _c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "text-align": "center"
    }
  }, [_vm._v(" Periksa Pesanan ")]), _c('c-box', {
    attrs: {
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['0 12px', '30px'],
      "margin-top": ['22px', '20px']
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "20px"
    }
  }, [_vm._v(" Detail Program ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "as": "a",
      "color": "#D32737"
    },
    on: {
      "click": _vm.handleChangeProgram
    }
  }, [_vm._v(" Ganti Program ")])], 1), _c('BaseDivider'), _c('c-box', {
    attrs: {
      "padding": ['0 7px', '0']
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": ['10px', '30px'],
      "align-items": "center",
      "margin": ['20px 0', '40px 0']
    }
  }, [_vm.productImage ? _c('c-image', {
    attrs: {
      "border-radius": "12px",
      "width": ['70px', '120px'],
      "height": ['89px', '153px'],
      "object-fit": "cover",
      "src": _vm.productImage,
      "alt": "Product Image"
    }
  }) : _vm._e(), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" " + _vm._s(_vm.productName) + " " + _vm._s(_vm.productServiceName) + " (" + _vm._s(_vm.duration) + ") ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "as": "a"
    },
    on: {
      "click": function click($event) {
        var _vm$product;

        return _vm.handleShowDetailProgram({
          isKonsultasi: ((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.isKonsultasi) === 1
        });
      }
    }
  }, [_vm._v(" Selengkapnya ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.subTotalPrice)) + " ")])], 1)], 1), _c('BaseDivider'), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "start",
      "gap": "10px",
      "margin": ['16px 0', '20px 0']
    }
  }, [_c('FormInput', {
    attrs: {
      "is-required": "",
      "is-disabled": _vm.couponDetail.isValid,
      "is-invalid": _vm.couponDetail.isValid === false,
      "label": "Kode Kupon",
      "placeholder": "Masukkan kode kupon",
      "icon-left-element": require('@/assets/icons/icon-coupon.svg'),
      "icon-left-element-color": _vm.couponDetail.isValid === false ? '#D32737' : '#008C81',
      "full-width": "",
      "is-helper": "",
      "helper-text-type": "questionmark",
      "helper-text": "Pelajari lebih lanjut",
      "helper-text-color": "#008C81",
      "is-without-margin-bottom": ""
    },
    on: {
      "blur": _vm.$v.couponCode.$touch,
      "helper-text-click": _vm.handleShowFaqCoupon,
      "trigger-change": function triggerChange($event) {
        _vm.couponDetail.isValid = null;
      }
    },
    model: {
      value: _vm.couponCode,
      callback: function callback($$v) {
        _vm.couponCode = $$v;
      },
      expression: "couponCode"
    }
  }), _c('BaseButton', {
    attrs: {
      "color": _vm.couponDetail.isValid ? 'danger' : 'primary',
      "size": "small",
      "variant": "contained",
      "border-radius": "1000px",
      "margin-top": ['33px', '35px'],
      "padding": "0 20px",
      "disabled": _vm.$v.couponCode.$invalid,
      "is-loading": _vm.isLoadingApplyCoupon
    },
    on: {
      "click": _vm.handleApplyCoupon
    }
  }, [_vm._v(" " + _vm._s(_vm.couponDetail.isValid ? 'Hapus' : 'Terapkan') + " ")])], 1), _c('BaseDivider', {
    attrs: {
      "display": ['none', 'block']
    }
  }), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "20px",
      "margin-top": ['30px', '20px']
    }
  }, [_vm._v(" Detail Pembayaran ")]), _c('BaseDivider'), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "gap": "10px",
      "margin-top": ['16px', '20px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Subtotal ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.subTotalPrice)) + " ")])], 1), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "gap": "10px",
      "margin-top": ['16px', '20px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Potongan ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.discount)) + " ")])], 1)], 1), _c('BaseDivider', {
    attrs: {
      "display": ['block', 'none'],
      "margin-top": ['16px', '20px']
    }
  }), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "gap": "10px",
      "margin-top": ['16px', '20px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "18px"
    }
  }, [_vm._v(" Total ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPrice)) + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "padding": ['0 12px', '0'],
      "gap": ['4px', '16px'],
      "margin-top": ['16px', '20px']
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "outlined",
      "w": "100%",
      "border-radius": "1000px"
    },
    on: {
      "click": _vm.handlePrevious
    }
  }, [_vm._v(" Kembali ")]), _c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "contained",
      "w": "100%",
      "border-radius": "1000px",
      "is-loading": _vm.isLoadingCheckout
    },
    on: {
      "click": _vm.handleNext
    }
  }, [_vm._v(" Selanjutnya ")])], 1), _c('ModalCouponLearnMore', {
    attrs: {
      "is-open": _vm.isOpenModalCouponLearnMore
    },
    on: {
      "set-is-open": function setIsOpen($event) {
        _vm.isOpenModalCouponLearnMore = $event;
      }
    }
  }), _c('ModalChangeProgram', {
    attrs: {
      "is-open": _vm.isOpenModalChangeProgram
    },
    on: {
      "set-is-open": function setIsOpen($event) {
        _vm.isOpenModalChangeProgram = $event;
      },
      "on-confirm": _vm.handleOnConfirmChangeProgram
    }
  }), _c('ModalCouponInvalid', {
    attrs: {
      "is-open": _vm.isOpenModalCouponInvalid
    },
    on: {
      "handleClose": function handleClose($event) {
        _vm.isOpenModalCouponInvalid = false;
      }
    }
  }), _c('ModalInfo', {
    attrs: {
      "for": "errorCannotPurchaseDifferentProgram",
      "with-button-close": false,
      "is-open": _vm.isOpenModalErrorProgramCannotPurchase
    },
    on: {
      "close": _vm.close
    }
  }), _c('ModalErrorProgramCannotPurchaseKonsultation', {
    attrs: {
      "is-open": _vm.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation
    },
    on: {
      "handleClose": function handleClose($event) {
        _vm.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }